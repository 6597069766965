import { Component, HostListener } from '@angular/core'
import { ModalRef } from './modal-ref'

@Component({
  selector: 'kdgh-modal',
  template:
    '<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true"><ng-content></ng-content></div>',
  /* eslint-disable @angular-eslint/no-host-metadata-property */
  host: {
    class: 'modal is-active',
  },
})
export class ModalComponent {
  constructor(private modalRef: ModalRef) {}

  @HostListener('keydown.esc')
  onEscape() {
    this.modalRef.close()
  }
}
