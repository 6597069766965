import { Component, HostBinding, Input } from '@angular/core'
import { evaluateBooleanInput } from '../input-utils'

@Component({
  selector: 'kdgh-modal-content',
  template: '<ng-content></ng-content>',
  /* eslint-disable @angular-eslint/no-host-metadata-property */
  host: {
    class: 'modal-content',
  },
  styles: [
    `
      :host {
        display: block;
        overflow: visible;
      }

      :host.is-tall-modal {
        max-width: 400px;
      }
    `,
  ],
})
export class ModalContentComponent {
  @Input()
  tall: boolean | string = false

  @HostBinding('class.is-tall-modal')
  get _isTallModalClass(): boolean {
    return evaluateBooleanInput(this.tall)
  }
}
