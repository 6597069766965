import { OverlayRef } from '@angular/cdk/overlay'
import { Subject } from 'rxjs'

export class ModalRef<C = void, R = void> {
  result?: R

  constructor(
    private readonly overlayRef: OverlayRef,
    public readonly context?: C
  ) {}

  onClose = new Subject<void>()
  onResult = new Subject<R>()

  close(result?: R) {
    this.result = result

    this.onClose.next()
    this.onClose.complete()

    this.overlayRef.dispose()

    if (result) {
      this.onResult.next(result)
    }
    this.onResult.complete()
  }
}
