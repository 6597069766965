import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule } from '@angular/material/dialog'
import { RouterModule } from '@angular/router'
import { BlmNavbarModule } from 'bulma-angular'
import { CenterdColumnComponent } from './centerd-column/centerd-column.component'
import { ConsentModalComponent } from './consent-modal/consent-modal.component'
import { ErrorMessageDirective } from './forms/error-message.directive'
import { ShowStatusDirective } from './forms/show-status.directive'
import { MainNavBarComponent } from './main-nav-bar/main-nav-bar.component'
import { ModalModule } from './modal'
import { NotFoundComponent } from './not-found/not-found.component'
import { PageComponent } from './page/page.component'
import { SidenavLayoutModule } from './sidenav-layout/sidenav-layout.module'
import { SignUpModalComponent } from './sign-up-modal/sign-up-modal.component'
import { SignUpGuard } from './sign-up.guard'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SidenavLayoutModule,
    OverlayModule,
    ReactiveFormsModule,
    BlmNavbarModule,
    MatDialogModule,
    FormsModule,
    ModalModule,
  ],
  declarations: [
    PageComponent,
    CenterdColumnComponent,
    ShowStatusDirective,
    MainNavBarComponent,
    ErrorMessageDirective,
    NotFoundComponent,
    SignUpModalComponent,
    ConsentModalComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    PageComponent,
    CenterdColumnComponent,
    MainNavBarComponent,
    ShowStatusDirective,
    ErrorMessageDirective,
    SidenavLayoutModule,
    ModalModule,
  ],
  providers: [SignUpGuard],
})
export class SharedModule {}
