import { Component, HostBinding, HostListener, Input } from '@angular/core'
import { evaluateBooleanInput } from '../input-utils'
import { ModalRef } from './modal-ref'

@Component({
  selector: 'kdgh-modal-background',
  template: '',
  /* eslint-disable @angular-eslint/no-host-metadata-property */
  host: {
    class: 'modal-background',
  },
  styleUrls: ['./modal-background.component.scss'],
})
export class ModalBackgroundComponent {
  @Input()
  closeOnClick: boolean | string = true

  @Input()
  opaque: boolean | string = false

  @HostBinding('class.is-opaque')
  get _isOpaqueClass(): boolean {
    return evaluateBooleanInput(this.opaque)
  }

  constructor(private modalRef: ModalRef) {}

  @HostListener('click')
  onClick() {
    if (evaluateBooleanInput(this.closeOnClick)) {
      this.modalRef.close()
    }
  }
}
