import { Component } from '@angular/core'
import { ModalRef } from '../modal'

@Component({
  selector: 'kdgh-consent-modal',
  templateUrl: './consent-modal.component.html',
  styles: [],
})
export class ConsentModalComponent {
  constructor(private modalRef: ModalRef<void, boolean>) {}

  continue(): void {
    this.modalRef.close(true)
  }

  cancel(): void {
    this.modalRef.close()
  }
}
