import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core'

@Directive({
  selector: '.navbar-item.has-dropdown', // eslint-disable-line @angular-eslint/directive-selector
})
export class BlmNavbarItemWithDropdownDirective {
  @HostBinding('class.is-active')
  dropdownIsActive = false

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  handleGlobalClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.dropdownIsActive = false
    }
  }

  toggleDropdown() {
    this.dropdownIsActive = !this.dropdownIsActive
  }
}
