import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BlmNavbarBurgerDirective } from './navbar-burger.directive'
import { BlmNavbarLinkDirective } from './navbar-link.directive'
import { BlmNavbarItemWithDropdownDirective } from './navbar-item-with-dropdown.directive'
import { BlmNavbarMenuDirective } from './navbar-menu.directive'
import { BlmNavbarDirective } from './navbar.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [
    BlmNavbarBurgerDirective,
    BlmNavbarMenuDirective,
    BlmNavbarDirective,
    BlmNavbarItemWithDropdownDirective,
    BlmNavbarLinkDirective,
  ],
  exports: [
    BlmNavbarBurgerDirective,
    BlmNavbarMenuDirective,
    BlmNavbarDirective,
    BlmNavbarItemWithDropdownDirective,
    BlmNavbarLinkDirective,
  ],
})
export class BlmNavbarModule {}
