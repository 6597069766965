import { Directive, HostBinding } from '@angular/core'
import { BlmNavbarDirective } from './navbar.directive'

@Directive({
  selector: '.navbar-menu', // eslint-disable-line @angular-eslint/directive-selector
})
export class BlmNavbarMenuDirective {
  constructor(private navbarDirective: BlmNavbarDirective) {}

  @HostBinding('class.is-active')
  get isActive(): boolean {
    return this.navbarDirective.hasActiveBurger
  }
}
