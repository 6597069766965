import { Directive, HostBinding, HostListener } from '@angular/core'

@Directive({
  selector: '.navbar-burger', // eslint-disable-line @angular-eslint/directive-selector
})
export class BlmNavbarBurgerDirective {
  @HostBinding('class.is-active')
  @HostBinding('attr.aria-expanded')
  isActive = false

  @HostListener('click')
  handleClick() {
    this.isActive = !this.isActive
  }
}
