import { EventEmitter, Injectable } from '@angular/core'
import { Observable } from 'rxjs'

const localeOverrideStorageKey = 'kdgh:memberPortal:locale'

export enum AppLocale {
  de = 'de',
  en = 'en',
}

export enum DownloadLocale {
  de = 'de',
  en = 'en',
}

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  constructor() {
    const buildLocale = location.pathname.split('/')[1]
    if (buildLocale in AppLocale) {
      this._isLocalizedBuild = true
      this._locale = buildLocale as AppLocale
    } else {
      this.locale = this.localeOverride ?? AppLocale.de
    }
  }

  private _isLocalizedBuild = false

  get localeChange(): Observable<string> {
    return this._localeChange
  }
  private readonly _localeChange = new EventEmitter<string>()

  get locale(): AppLocale {
    return this._locale
  }
  private _locale: AppLocale

  set locale(locale: AppLocale) {
    if (this._isLocalizedBuild) {
      const pathSegments = location.pathname.split('/')
      pathSegments[1] = locale
      location.pathname = pathSegments.join('/')
    } else {
      this._locale = this.localeOverride = locale
      this._localeChange.emit(locale)
    }
  }

  private get localeOverride(): AppLocale | undefined {
    return localStorage.getItem(localeOverrideStorageKey) as
      | AppLocale
      | undefined
  }

  private set localeOverride(locale: AppLocale | undefined) {
    if (locale) {
      localStorage.setItem(localeOverrideStorageKey, locale)
    } else {
      localStorage.removeItem(localeOverrideStorageKey)
    }
  }

  get downloadLocale(): DownloadLocale {
    if (this._downloadsLocale) {
      return this._downloadsLocale
    }

    return this.locale.startsWith('de') ? DownloadLocale.de : DownloadLocale.en
  }

  set downloadLocale(locale: DownloadLocale) {
    this._downloadsLocale = locale
  }

  private _downloadsLocale: DownloadLocale | undefined
}
