import {
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core'
import { BlmNavbarItemWithDropdownDirective } from './navbar-item-with-dropdown.directive'

@Directive({
  selector: '.navbar-link', // eslint-disable-line @angular-eslint/directive-selector
})
export class BlmNavbarLinkDirective implements OnInit {
  get isDropdownTrigger() {
    return !!this.parentNavbarItemWithDropdown
  }

  constructor(
    @Optional()
    private parentNavbarItemWithDropdown: BlmNavbarItemWithDropdownDirective,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.isDropdownTrigger) {
      const { nativeElement } = this.elementRef
      this.renderer.setAttribute(nativeElement, 'tabindex', '0')
      this.renderer.setAttribute(nativeElement, 'role', 'button')
    }
  }

  @HostListener('click')
  @HostListener('keydown.space')
  @HostListener('keydown.enter')
  handleActivation() {
    if (this.isDropdownTrigger) {
      this.parentNavbarItemWithDropdown.toggleDropdown()
    }
  }
}
