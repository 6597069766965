import {
  Directive,
  HostBinding,
  Inject,
  Optional,
  Self,
  SkipSelf,
} from '@angular/core'
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input:not([noShowStatus]),textarea:not([noShowStatus])',
})
export class ShowStatusDirective {
  @HostBinding('class.is-danger')
  get isDanger() {
    return this.showStatus && this.control.invalid
  }

  @HostBinding('class.is-success')
  get isSuccess() {
    return this.showStatus && this.control.valid
  }

  private form: NgForm | FormGroupDirective | null

  private get showStatus() {
    if (this.control.touched) {
      return this.control.touched
    }

    if (this.form !== null) {
      return this.form.submitted && this.control.invalid
    }

    return false
  }

  constructor(
    @SkipSelf()
    @Optional()
    @Inject(NgForm)
    ngForm: NgForm | null,
    @SkipSelf()
    @Optional()
    @Inject(FormGroupDirective)
    formGroupDirective: FormGroupDirective | null,
    @Self()
    private control: NgControl
  ) {
    this.form = ngForm || formGroupDirective
  }
}
