import { ContentChildren, Directive, QueryList } from '@angular/core'
import { BlmNavbarBurgerDirective } from './navbar-burger.directive'

@Directive({
  selector: '.navbar', // eslint-disable-line @angular-eslint/directive-selector
})
export class BlmNavbarDirective {
  @ContentChildren(BlmNavbarBurgerDirective, { descendants: true })
  burgerDirectives: QueryList<BlmNavbarBurgerDirective>

  get hasActiveBurger(): boolean {
    if (this.burgerDirectives) {
      return this.burgerDirectives.some(
        burgerDirective => burgerDirective.isActive
      )
    }

    return false
  }
}
