<kdgh-modal>
  <kdgh-modal-background opaque></kdgh-modal-background>
  <kdgh-modal-content tall>
    <div class="card">
      <div class="card-content">
        <h1 class="title is-3" i18n>Datenschutz</h1>

        <p class="subtitle is-5">Katechese des Guten Hirten e.V. Deutschland</p>

        <div class="hr"></div>

        <p i18n>
          Durch fortfahren bestätigen Sie, dass Sie die
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.katechesedesgutenhirten.de/datenschutzerklaerung"
            >Datenschutzerklärung</a
          >
          gelesen haben und diese akzeptieren.
        </p>

        <button
          class="button is-primary is-fullwidth mt-4"
          (click)="continue()"
          i18n
        >
          Fortfahren
        </button>

        <button class="button is-fullwidth mt-4" (click)="cancel()" i18n>
          Abbrechen
        </button>
      </div>
    </div>
  </kdgh-modal-content>
</kdgh-modal>
