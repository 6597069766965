import { Directive, HostBinding } from '@angular/core'

let dropdownId = 0

@Directive({
  selector: '.dropdown-menu', // eslint-disable-line @angular-eslint/directive-selector
})
export class BlmDropdownMenuDirective {
  @HostBinding('attr.role')
  role = 'menu'

  @HostBinding('id')
  id = `dropdown-${dropdownId++}`
}
