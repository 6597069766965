import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SignUpGuard } from './shared'
import { NotFoundComponent } from './shared/not-found/not-found.component'

const routes: Routes = [
  {
    path: '',
    canActivate: [SignUpGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/material/deutsch' },
      {
        path: 'material',
        loadChildren: () =>
          import('./material/material.module').then(m => m.MaterialModule),
      },
      { path: '**', component: NotFoundComponent },
    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
