import { Component } from '@angular/core'
import { NgForm } from '@angular/forms'
import { UserService } from 'src/app/core'
import { PersonNameInput } from 'src/generated/graphql'
import { ModalRef } from '../modal'

@Component({
  selector: 'kdgh-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
})
export class SignUpModalComponent {
  name: PersonNameInput = { familyName: '', givenName: '' }

  pending = false

  constructor(private modalRef: ModalRef, private userService: UserService) {}

  signUp(form: NgForm) {
    if (form.valid) {
      this.pending = true
      this.userService.signUp({ name: this.name }).then(() => {
        this.pending = false
        this.modalRef.close()
      })
    }
  }

  cancel() {
    this.userService.signOut()
    this.modalRef.close()
  }
}
