<kdgh-modal>
  <kdgh-modal-background [closeOnClick]="false" opaque></kdgh-modal-background>

  <kdgh-modal-content tall>
    <div class="card">
      <div class="card-content">
        <h1 class="title is-3" i18n>Registrierung abschließen</h1>

        <p class="subtitle is-5">Katechese des Guten Hirten e.V. Deutschland</p>

        <div class="hr"></div>

        <form (ngSubmit)="signUp(form)" #form="ngForm">
          <div class="field">
            <label for="givenName" class="label" i18n>Vorname</label>
            <div class="control">
              <input
                id="givenName"
                name="givenName"
                class="input"
                type="text"
                required
                maxlength="256"
                [(ngModel)]="name.givenName"
              />
            </div>
          </div>

          <div class="field">
            <label for="familyName" class="label" i18n>Nachname</label>
            <div class="control">
              <input
                id="familyName"
                name="familyName"
                class="input"
                type="text"
                required
                maxlength="256"
                [(ngModel)]="name.familyName"
              />
            </div>
          </div>

          <div class="field mt-6">
            <div class="buttons is-right">
              <button
                class="button is-text"
                type="button"
                (click)="cancel()"
                [disabled]="pending"
                i18n
              >
                Abbrechen
              </button>
              <button
                class="button is-primary"
                [class.is-loading]="pending"
                type="submit"
                i18n
              >
                Weiter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </kdgh-modal-content>
</kdgh-modal>
