import { A11yModule } from '@angular/cdk/a11y'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ModalBackgroundComponent } from './modal-background.component'
import { ModalCardBodyComponent } from './modal-card-body.component'
import { ModalCardCancelComponent } from './modal-card-cancel.component'
import { ModalCardCloseComponent } from './modal-card-close.component'
import { ModalCardFootComponent } from './modal-card-foot.component'
import { ModalCardHeadComponent } from './modal-card-head.component'
import { ModalCardTitleComponent } from './modal-card-title.component'
import { ModalCardComponent } from './modal-card.component'
import { ModalCloseComponent } from './modal-close.component'
import { ModalContentComponent } from './modal-content.component'
import { ModalComponent } from './modal.component'
import { ModalService } from './modal.service'

@NgModule({
  imports: [CommonModule, OverlayModule, A11yModule],
  declarations: [
    ModalComponent,
    ModalBackgroundComponent,
    ModalCloseComponent,
    ModalContentComponent,
    ModalCardComponent,
    ModalCardHeadComponent,
    ModalCardTitleComponent,
    ModalCardCloseComponent,
    ModalCardBodyComponent,
    ModalCardFootComponent,
    ModalCardCancelComponent,
  ],
  exports: [
    ModalComponent,
    ModalBackgroundComponent,
    ModalCloseComponent,
    ModalContentComponent,
    ModalCardComponent,
    ModalCardHeadComponent,
    ModalCardTitleComponent,
    ModalCardCloseComponent,
    ModalCardBodyComponent,
    ModalCardFootComponent,
    ModalCardCancelComponent,
  ],
  providers: [ModalService],
})
export class ModalModule {}
