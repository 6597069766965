import { Injectable } from '@angular/core'
import { ModalService } from '../modal'
import { SignUpModalComponent } from './sign-up-modal.component'

@Injectable({
  providedIn: 'root',
})
export class SignUpModalService {
  constructor(private modal: ModalService) {}

  showModal(): Promise<void> {
    return this.modal.open(SignUpModalComponent).onClose.toPromise()
  }
}
