import { HttpErrorResponse } from '@angular/common/http'
import { ErrorHandler, Injectable } from '@angular/core'
import { isApolloError } from '@apollo/client/core'
import * as Sentry from '@sentry/browser'
import { defaultIntegrations, Integrations } from '@sentry/browser'
import { BUILD_INFO } from '../../environments/build-info'
import { environment } from '../../environments/environment'

if (environment.sentry) {
  Sentry.init({
    ...environment.sentry,
    release: `${BUILD_INFO.version}-${BUILD_INFO.rev}`,
    defaultIntegrations: defaultIntegrations.filter(
      integration => !(integration instanceof Integrations.TryCatch)
    ),
  })
}

@Injectable()
export class KdGHErrorHandler extends ErrorHandler {
  handleError(error: any): void {
    if (environment.sentry) {
      Sentry.captureException(error.originalError || error)
      Sentry.showReportDialog()
    }

    super.handleError(error)

    const err = error.rejection || error

    if (isApolloError(err)) {
      if (err.graphQLErrors) {
        console.error('ApolloError.graphQLErrors', err.graphQLErrors)
      }

      if (err.networkError) {
        const networkError = err.networkError as HttpErrorResponse
        console.error('ApolloError.networkError', networkError)
      }
    }
  }
}
