import { Overlay } from '@angular/cdk/overlay'
import { ComponentPortal, ComponentType } from '@angular/cdk/portal'
import { Injectable, Injector } from '@angular/core'
import { ModalRef } from './modal-ref'

export class ModalAlreadyOpenError extends Error {
  constructor() {
    super('Another modal has already been opened.')
  }
}

@Injectable()
export class ModalService {
  modalIsOpen = false

  constructor(private overlay: Overlay, private injector: Injector) {}

  open<C, R = void>(comp: ComponentType<any>, context?: C): ModalRef<C, R> {
    if (this.modalIsOpen) {
      throw new ModalAlreadyOpenError()
    }

    const overlayRef = this.overlay.create({
      hasBackdrop: false,
      positionStrategy: this.overlay.position().global(),
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    })

    const modalRef = new ModalRef<C, R>(overlayRef, context)
    const modalInjector = this.createModalInjector(modalRef)
    const componentPortal = new ComponentPortal(comp, null, modalInjector)

    modalRef.onClose.subscribe(() => (this.modalIsOpen = false))
    this.modalIsOpen = true

    overlayRef.attach(componentPortal)

    return modalRef
  }

  private createModalInjector(modalRef: ModalRef<any, any>): Injector {
    return Injector.create({
      providers: [{ provide: ModalRef, useValue: modalRef }],
      parent: this.injector,
    })
  }
}
