import { Component, OnInit } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'kdgh-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  ngOnInit(): void {
    if (environment.sentry) {
      Sentry.captureMessage(`NotFound: ${location.pathname}`, 'warning')
    }
  }

  get url() {
    return location.href
  }
}
