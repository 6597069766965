import { Injectable } from '@angular/core'
import { ModalService } from '../modal'
import { ConsentModalComponent } from './consent-modal.component'

@Injectable({
  providedIn: 'root',
})
export class ConsentModalService {
  constructor(private modal: ModalService) {}

  showModal(): Promise<boolean> {
    const modalRef = this.modal.open<void, boolean>(ConsentModalComponent)
    return modalRef.onClose.toPromise().then(() => modalRef.result === true)
  }
}
