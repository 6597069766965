import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router'
import { combineLatest, Observable } from 'rxjs'
import { mergeMap, take } from 'rxjs/operators'
import { UserService } from '../core'
import { SignUpModalService } from './sign-up-modal/sign-up-modal.service'

@Injectable()
export class SignUpGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private signUpModalService: SignUpModalService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return combineLatest([
      this.userService.isAuthenticated$,
      this.userService.isSignedUp$,
    ]).pipe(
      take(1),
      mergeMap(async ([isAuthenticated, isSignedUp]) => {
        if (isAuthenticated && !isSignedUp) {
          await this.signUpModalService.showModal()
        }

        return true
      })
    )
  }
}
