import { Environment } from './environment.interface'

export const environment: Environment = {
  production: true,
  apiServerOrigin: 'https://staging.api.admin.kdgh.de',
  auth0: {
    domain: 'kdgh-admin-staging.eu.auth0.com',
    clientId: 'k9MoUIkk7Ys9c3687f4XiQqytrqpf2pA',
    audience: 'https://staging.api.admin.kdgh.de',
  },
  sentry: {
    dsn: 'https://12fb3f27b89c436a96c7e0437e7d8d82@sentry.io/1315455',
  },
  contentful: {
    spaceId: 'pgexwno8lmbw',
    accessToken: 'HUpnEgqvWIABU_ZjD_lW25ZPY0Q7bi1SLtnAv2lPMcw',
    previewAccessToken: 'BIBXaEuTPPj0zK2gPmd5Ht4F0t4HCvcFxYy8SG-KQCQ',
    environment: 'master',
  },
}
