import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker'
import {
  AuthHttpInterceptor,
  AuthModule,
  AuthService as Auth0Service,
} from '@auth0/auth0-angular'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ApiModule, KdGHErrorHandler } from './core'
import { ExperimentalFeaturesService } from './core/experimental.features.service'
import { SharedModule } from './shared'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApiModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AuthModule.forRoot({
      ...environment.auth0,
      useRefreshTokens: true,
      cacheLocation: 'memory',
      httpInterceptor: {
        allowedList: [`${environment.apiServerOrigin}/api*`],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: KdGHErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    swUpdate: SwUpdate,
    // Auth0Service must be injected here to ensure it is created early and
    // is able to see the redirect url from the authentication server.
    _: Auth0Service,
    experimentalFeatures: ExperimentalFeaturesService
  ) {
    swUpdate.available.subscribe(() => {
      swUpdate.activateUpdate().then(() => document.location.reload())
    })
    experimentalFeatures.init()
  }
}
