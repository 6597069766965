<kdgh-main-nav-bar></kdgh-main-nav-bar>

<div class="section">
  <div class="container">
    <h1 class="title is-1">Seite nicht gefunden</h1>

    <p i18n>
      Eine Seite mit der URL <strong>{{ url }}</strong> konnte nicht gefunden
      werden.
    </p>
  </div>
</div>
