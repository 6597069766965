import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core'
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { environment } from '../../../environments/environment'
import fragmentMatcher from '../../../generated/graphql-fragment-matcher'

function getApolloConfig(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({
      uri: `${environment.apiServerOrigin}/api/graphql`,
    }),
    cache: new InMemoryCache({ possibleTypes: fragmentMatcher.possibleTypes }),
  }
}

@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: getApolloConfig,
      deps: [HttpLink],
    },
  ],
})
export class ApiModule {}
