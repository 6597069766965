
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Connection": [
      "AuditEventConnection",
      "CourseConnection",
      "DataProcessingProcessConnection",
      "DocumentConnection",
      "OrganizationMembershipConnection",
      "PersonConnection"
    ],
    "DeleteRestricted": [
      "Course",
      "CourseAdminGroup",
      "CourseOptionBooking",
      "CourseOptionDescriptor",
      "Person",
      "PersonAddress",
      "PersonEmail",
      "PersonName",
      "PersonPhone"
    ],
    "Edge": [
      "AuditEventEdge",
      "CourseEdge",
      "DataProcessingProcessEdge",
      "DocumentEdge",
      "OrganizationMembershipEdge",
      "PersonEdge"
    ],
    "Named": [
      "Course",
      "CourseAdminGroup",
      "CourseEvent",
      "CourseOptionDescriptor",
      "CoursePart",
      "CourseParticipationCourseSummary",
      "Document",
      "DocumentTag",
      "ExpenseDescriptor",
      "Organization"
    ],
    "Node": [
      "AdminAppUser",
      "AuditEvent",
      "AuditResource",
      "Course",
      "CourseAdminFile",
      "CourseAdminGroup",
      "CourseEvent",
      "CourseEventAttendance",
      "CourseOptionBooking",
      "CourseOptionDescriptor",
      "CoursePart",
      "CourseParticipationCertificate",
      "DataProcessingActivity",
      "DataProcessingAgreementChangeEvent",
      "DataProcessingProcess",
      "Document",
      "DocumentTag",
      "Expense",
      "ExpenseDescriptor",
      "ExpenseReceipt",
      "MemberPortalUser",
      "Organization",
      "OrganizationMembership",
      "Payment",
      "Person",
      "UserAccount"
    ],
    "PersonScopeData": [
      "PersonAddress",
      "PersonEmail",
      "PersonName",
      "PersonPhone"
    ],
    "ProcessResource": [
      "AdminAppUser",
      "CourseAdminFile",
      "CourseParticipationCertificate",
      "MemberPortalUser",
      "OrganizationMembership"
    ],
    "Timestamped": [
      "AdminAppUser",
      "Course",
      "CourseAdminFile",
      "CourseAdminGroup",
      "CoursePart",
      "Document",
      "DocumentTag",
      "MemberPortalUser",
      "Organization",
      "OrganizationMembership",
      "Person"
    ],
    "UpdateRestricted": [
      "CourseOptionBooking",
      "CourseOptionDescriptor"
    ]
  }
};
      export default result;
    