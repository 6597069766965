import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService as Auth0Service } from '@auth0/auth0-angular'
import moment from 'moment'
import { Observable, of } from 'rxjs'
import { map, shareReplay, switchMap } from 'rxjs/operators'
import {
  GetUserAccountGQL,
  PersonNameInput,
  SignUpUserGQL,
  UserAccountFragment,
} from 'src/generated/graphql'
import { ConsentModalService } from '../shared/consent-modal/consnet-modal.service'

/**
 * The key under which the date on which the user accepted the data privacy
 * agreement is saved in local storage.
 */
const dataPrivacyAgreementConsentKey = 'kdgh:dataPrivacyAgreementConsentDate'

/**
 * The date when the current data privacy agreement became effective.
 */
const dataPrivacyAgreementDate = moment('2021-07-01T00:00:00')

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private auth: Auth0Service,
    private router: Router,
    private getUserAccountGql: GetUserAccountGQL,
    private signUpUserGql: SignUpUserGQL,
    private consentModal: ConsentModalService
  ) {}

  readonly isAuthenticated$ = this.auth.isAuthenticated$

  readonly userAccount$: Observable<UserAccountFragment | null> =
    this.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          return of(null)
        }

        return this.getUserAccountGql
          .watch()
          .valueChanges.pipe(
            map(result => result.data.viewerUserAccount ?? null)
          )
      }),
      shareReplay(1)
    )

  readonly isSignedUp$: Observable<boolean> = this.userAccount$.pipe(
    map(userAccount => userAccount !== null)
  )

  readonly displayName$: Observable<string | null> = this.userAccount$.pipe(
    map(userAccount => userAccount?.person?.displayName)
  )

  get needsToGiveConsent(): boolean {
    const storedConsentDate = localStorage.getItem(
      dataPrivacyAgreementConsentKey
    )
    if (!storedConsentDate) {
      return true
    }
    return moment(storedConsentDate).isBefore(dataPrivacyAgreementDate)
  }

  recordConsent(): void {
    localStorage.setItem(dataPrivacyAgreementConsentKey, moment().toISOString())
  }

  async signIn({ target }: { target?: string } = {}) {
    if (this.needsToGiveConsent) {
      const gaveConsent = await this.consentModal.showModal()
      if (gaveConsent) {
        this.recordConsent()
      } else {
        return
      }
    }

    this.auth.loginWithRedirect({
      prompt: 'login',
      appState: { target: target ?? this.router.routerState.snapshot.url },
    })
  }

  signOut() {
    this.auth.logout()
  }

  signUp(userData: { name: PersonNameInput }): Promise<any> {
    return this.signUpUserGql
      .mutate(
        {
          input: {
            ...userData,
          },
        },
        {
          refetchQueries: ['GetUserAccount'],
        }
      )
      .toPromise()
  }
}
