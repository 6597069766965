import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A calendar date without time. */
  Date: any;
  /** A calendar date with time. */
  DateTime: any;
  /** A duration of time in seconds. */
  Duration: any;
  /** A JSON value. */
  JSON: any;
};

/** An `AdminAppUser` enables a `Person` to access the system. */
export type AdminAppUser = Node & ProcessResource & Timestamped & {
  __typename?: 'AdminAppUser';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /**
   * Whether this `AdminAppUser` has been archived.
   *
   * `AdminAppUser`s can only be deleted after having been archived and retained for a certain amount
   * of time.
   *
   * Archived `AdminAppUser`s cannot sign in and access the system.
   */
  isArchived: Scalars['Boolean'];
  modifiedAt: Scalars['DateTime'];
  /** The `Person` this `AdminAppUser` belongs to. */
  person: Person;
  process: DataProcessingProcess;
  /** The roles of this `AdminAppUser`. */
  roles: Array<UserRole>;
};

/**
 * An event that contains information for auditing of the system, such as when certain actions where
 * invoked anb by whom.
 */
export type AuditEvent = Node & {
  __typename?: 'AuditEvent';
  /**
   * Additional details, relevant to this event. No guaranties are made about the content of this
   * field. It exists for the purpose of giving additional context to a human auditor.
   *
   * To ensure compliance with data protection requirements, this field is set to `null` 1 year
   * after this event was recorded.
   */
  details?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  /** A set of resources which where relevant to the event, when it occurred. */
  resources: Array<Maybe<AuditResource>>;
  /** The date when this event was recorded. */
  time: Scalars['DateTime'];
  /** The type of this event. */
  type: AuditEventType;
};

export type AuditEventConnection = Connection & {
  __typename?: 'AuditEventConnection';
  edges: Array<AuditEventEdge>;
  pageInfo: PageInfo;
};

export type AuditEventEdge = Edge & {
  __typename?: 'AuditEventEdge';
  cursor: Scalars['String'];
  node: AuditEvent;
};

/** The type of an `AuditEvent`. */
export enum AuditEventType {
  CreateResource = 'CreateResource',
  DeleteResource = 'DeleteResource',
  UpdateResource = 'UpdateResource'
}

/** A resources which is relevant to an `AuditEvent`. */
export type AuditResource = Node & {
  __typename?: 'AuditResource';
  /** The classifier of this `AuditResource`. */
  classifier: AuditResourceClassifier;
  id: Scalars['ID'];
  /** The actual resource if it still exists. */
  resource?: Maybe<Node>;
  /** The id of the resource. */
  resourceId: Scalars['ID'];
  /** The typ of the resource. */
  resourceType: Scalars['String'];
};

/** A classifier which determines the relationship of an `AuditResource` to an `AuditEvent`. */
export enum AuditResourceClassifier {
  /** The resource is the actor which triggered the event. */
  Actor = 'Actor',
  /** The resource is the target of the event. */
  EventTarget = 'EventTarget',
  /** The resource is a container of the event target resource. */
  TargetContainer = 'TargetContainer'
}

/** Matcher for filtering `AuditResources`. */
export type AuditResourceMatcher = {
  /** The classifier a resources needs to have to match. */
  classifier: AuditResourceClassifier;
  /** The id a resources needs to have to match. */
  resourceId: Scalars['ID'];
};

/** A combination of `AuditResourceMatcher`. */
export type AuditResourceMatchers = {
  /** An event needs to match all of the matchers to be matched. */
  and?: InputMaybe<Array<AuditResourceMatcher>>;
  /** An event need to match at least one of the matchers to be matched. */
  or?: InputMaybe<Array<AuditResourceMatcher>>;
};

/** An interface to support paging through results. */
export type Connection = {
  /** The set of `Edge`s included in this connection. */
  edges: Array<Edge>;
  /** The `PageInfo` of this connection. */
  pageInfo: PageInfo;
};

export type Course = DeleteRestricted & Named & Node & Timestamped & {
  __typename?: 'Course';
  adminGroups: Array<CourseAdminGroup>;
  canBeDeleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  level: CourseLevel;
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  organization: Organization;
  parts: Array<CoursePart>;
};

export type CourseAdminFile = Node & ProcessResource & Timestamped & {
  __typename?: 'CourseAdminFile';
  adminGroup: CourseAdminGroup;
  createdAt: Scalars['DateTime'];
  eventAttendances: Array<CourseEventAttendance>;
  expenseReceipts: Array<ExpenseReceipt>;
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  /** Whether this file has been rescinded. */
  isRescinded: Scalars['Boolean'];
  modifiedAt: Scalars['DateTime'];
  optionBookings: Array<CourseOptionBooking>;
  person: Person;
  process: DataProcessingProcess;
};

export type CourseAdminGroup = DeleteRestricted & Named & Node & Timestamped & {
  __typename?: 'CourseAdminGroup';
  adminFiles: Array<CourseAdminFile>;
  /** If this group is archived, the date and time at which it was archived. */
  archivedAt?: Maybe<Scalars['DateTime']>;
  canBeDeleted: Scalars['Boolean'];
  course: Course;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /**
   * Whether this group is archived.
   *
   * Only **Admin** users can update this field.
   *
   * The following mutations cannot be called while a group is archived:
   *
   * - `updateCourseAdminGroup`, except to change `isArchived`
   * - `createCourseAdminFile`
   * - `updateCourseAdminFile`
   * - `createCourseOptionDescriptor`
   * - `updateCourseOptionDescriptor`
   * - `deleteCourseOptionDescriptor`
   *
   * The following REST endpoints cannot be called wile a group is archived:
   *
   * `api/courseAdminGroups/{adminGroupId}/expenseReceipts/generate`
   */
  isArchived: Scalars['Boolean'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  optionDescriptors: Array<CourseOptionDescriptor>;
  parts: Array<CoursePart>;
};

export type CourseConnection = Connection & {
  __typename?: 'CourseConnection';
  edges: Array<CourseEdge>;
  pageInfo: PageInfo;
};

export type CourseEdge = Edge & {
  __typename?: 'CourseEdge';
  cursor: Scalars['String'];
  node: Course;
};

export type CourseEvent = Named & Node & {
  __typename?: 'CourseEvent';
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  part: CoursePart;
  startDate: Scalars['DateTime'];
};

export type CourseEventAttendance = Node & {
  __typename?: 'CourseEventAttendance';
  customDuration?: Maybe<Scalars['Duration']>;
  event: CourseEvent;
  id: Scalars['ID'];
};

export type CourseEventAttendanceInput = {
  customDuration?: InputMaybe<Scalars['Duration']>;
  id: Scalars['ID'];
};

export enum CourseLevel {
  Four = 'Four',
  Material = 'Material',
  One = 'One',
  Publications = 'Publications',
  Three = 'Three',
  Two = 'Two',
  Zero = 'Zero'
}

export type CourseOptionBooking = DeleteRestricted & Node & UpdateRestricted & {
  __typename?: 'CourseOptionBooking';
  canBeDeleted: Scalars['Boolean'];
  canBeUpdated: Scalars['Boolean'];
  descriptor: CourseOptionDescriptor;
  expense: Expense;
  id: Scalars['ID'];
};

export type CourseOptionDescriptor = DeleteRestricted & Named & Node & UpdateRestricted & {
  __typename?: 'CourseOptionDescriptor';
  canBeDeleted: Scalars['Boolean'];
  canBeUpdated: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  expenseDescriptor: ExpenseDescriptor;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CoursePart = Named & Node & Timestamped & {
  __typename?: 'CoursePart';
  createdAt: Scalars['DateTime'];
  endDate: Scalars['Date'];
  events: Array<CourseEvent>;
  id: Scalars['ID'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
};

/** A record of a person's participation in a course. */
export type CourseParticipationCertificate = Node & ProcessResource & {
  __typename?: 'CourseParticipationCertificate';
  /**
   * The course admin file this certificate is connected to, or `null` if
   * has been created manually.
   *
   * A certificate connected to an admin file cannot be updated or deleted.
   */
  adminFile?: Maybe<CourseAdminFile>;
  /**
   * The completion of the course in percent.
   *
   * If `courseSummary.duration` exists, completion relates to this amount of time.
   */
  completion?: Maybe<Scalars['Float']>;
  courseSummary: CourseParticipationCourseSummary;
  /** Whether a file exists which documents the participation. */
  hasDocument: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The person this certificate belongs to and whose participation is certified by it. */
  person: Person;
  /** The associated data processing process. */
  process: DataProcessingProcess;
};

/** Summary information about a course which is included in a `CourseParticipationCertificate`. */
export type CourseParticipationCourseSummary = Named & {
  __typename?: 'CourseParticipationCourseSummary';
  /** The amount of time that is required of participants to complete the course. */
  duration?: Maybe<Scalars['Duration']>;
  /** The level of the course. */
  level: CourseLevel;
  /** The name of the course. */
  name: Scalars['String'];
  /** The part of the course. */
  part: Scalars['String'];
  /** The year in which the course was held. */
  year: Scalars['Int'];
};

export type CourseParticipationCourseSummaryInput = {
  duration?: InputMaybe<Scalars['Duration']>;
  level: CourseLevel;
  name: Scalars['String'];
  part: Scalars['String'];
  year: Scalars['Int'];
};

export type CreateAdminAppUserInput = {
  personId: Scalars['ID'];
  roles?: InputMaybe<Array<UserRole>>;
};

export type CreateCourseAdminFileExpenseReceiptInput = {
  adminFileId: Scalars['ID'];
};

export type CreateCourseAdminFileInput = {
  groupId: Scalars['ID'];
  personId: Scalars['ID'];
};

export type CreateCourseAdminGroupInput = {
  courseId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateCourseEventInput = {
  coursePartId: Scalars['ID'];
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type CreateCourseInput = {
  level: CourseLevel;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CreateCourseOptionBookingInput = {
  adminFileId: Scalars['ID'];
  descriptorId: Scalars['ID'];
};

export type CreateCourseOptionBookingPaymentInput = {
  bookingId: Scalars['ID'];
  payment: PaymentInput;
};

export type CreateCourseOptionDescriptorInput = {
  description?: InputMaybe<Scalars['String']>;
  expenseAmount: MoneyInput;
  groupId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateCoursePartInput = {
  courseId: Scalars['ID'];
  endDate: Scalars['Date'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
};

export type CreateCourseParticipationCertificateInput = {
  completion?: InputMaybe<Scalars['Float']>;
  courseSummary: CourseParticipationCourseSummaryInput;
  personId: Scalars['ID'];
};

export type CreateDataProcessingAgreementChangeEventInput = {
  changes?: InputMaybe<Array<DataProcessingAgreementChangeInput>>;
  date: Scalars['Date'];
  personId: Scalars['ID'];
};

export type CreateDocumentTagInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CreateMemberPortalUserInput = {
  materialAccessCourseLevels?: InputMaybe<Array<CourseLevel>>;
  personId: Scalars['ID'];
};

export type CreateOrganizationInput = {
  address: PostalAddressInput;
  membershipRequiresContribution?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  officialName: Scalars['String'];
  receiptsFooter?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationMembershipInput = {
  /** The id of the organization to which the new membership belongs. */
  organizationId: Scalars['ID'];
  /** The id of the person which is the new member of the organization. */
  personId: Scalars['ID'];
};

export type CreatePersonInput = {
  email?: InputMaybe<PersonEmailInput>;
  homeAddress?: InputMaybe<PostalAddressInput>;
  name?: InputMaybe<PersonNameInput>;
  phone?: InputMaybe<PersonPhoneInput>;
};

export type DataProcessingActivity = Node & {
  __typename?: 'DataProcessingActivity';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  requirements: Array<DataProcessingRequirement>;
};

export type DataProcessingAgreementChange = {
  __typename?: 'DataProcessingAgreementChange';
  activity: DataProcessingActivity;
  permissionStatus: DataProcessingAgreementPermissionStatus;
};

export type DataProcessingAgreementChangeEvent = Node & {
  __typename?: 'DataProcessingAgreementChangeEvent';
  changes: Array<DataProcessingAgreementChange>;
  date: Scalars['Date'];
  hasDocumentation: Scalars['Boolean'];
  id: Scalars['ID'];
  /**
   * Whether this event is managed by the system.
   *
   * Managed events are not mutable by users.
   */
  isManaged: Scalars['Boolean'];
};

export type DataProcessingAgreementChangeInput = {
  activityId: Scalars['ID'];
  permissionStatus: DataProcessingAgreementPermissionStatus;
};

export enum DataProcessingAgreementPermissionStatus {
  Granted = 'Granted',
  Revoked = 'Revoked'
}

export type DataProcessingProcess = Node & {
  __typename?: 'DataProcessingProcess';
  activity: DataProcessingActivity;
  id: Scalars['ID'];
  /**
   * Whether the `Person` this process belongs to has given permission for the processing of their
   * data in this process.
   */
  permissionExists?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<ProcessResource>;
  retentionConfig?: Maybe<ProcessRetentionConfig>;
  retentionEnd?: Maybe<Scalars['DateTime']>;
  retentionStart?: Maybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
  state: ProcessState;
};

export type DataProcessingProcessConnection = Connection & {
  __typename?: 'DataProcessingProcessConnection';
  edges: Array<DataProcessingProcessEdge>;
  pageInfo: PageInfo;
};

export type DataProcessingProcessEdge = Edge & {
  __typename?: 'DataProcessingProcessEdge';
  cursor: Scalars['String'];
  node: DataProcessingProcess;
};

export type DataProcessingRequirement = {
  __typename?: 'DataProcessingRequirement';
  preventDeletion: Scalars['Boolean'];
  required: Scalars['Boolean'];
  scope: PersonScope;
};

export type DeleteAdminAppUserInput = {
  id: Scalars['ID'];
};

export type DeleteCourseAdminFileExpenseReceiptInput = {
  id: Scalars['ID'];
};

export type DeleteCourseAdminFileInput = {
  id: Scalars['ID'];
};

export type DeleteCourseAdminGroupInput = {
  id: Scalars['ID'];
};

export type DeleteCourseEventInput = {
  coursePartId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DeleteCourseInput = {
  id: Scalars['ID'];
};

export type DeleteCourseOptionBookingInput = {
  id: Scalars['ID'];
};

export type DeleteCourseOptionBookingPaymentInput = {
  bookingId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DeleteCourseOptionDescriptorInput = {
  id: Scalars['ID'];
};

export type DeleteCoursePartInput = {
  id: Scalars['ID'];
};

export type DeleteCourseParticipationCertificateInput = {
  id: Scalars['ID'];
};

export type DeleteDataProcessingAgreementChangeEventInput = {
  id: Scalars['ID'];
};

export type DeleteDocumentInput = {
  id: Scalars['ID'];
};

export type DeleteDocumentTagInput = {
  id: Scalars['ID'];
};

export type DeleteMemberPortalUserInput = {
  id: Scalars['ID'];
};

export type DeleteOrganizationInput = {
  id: Scalars['ID'];
};

export type DeleteOrganizationMembershipInput = {
  /** The id of the membership to delete. */
  id: Scalars['ID'];
};

export type DeletePersonInput = {
  id: Scalars['ID'];
};

/** A thing which has restrictions on when it can be deleted. */
export type DeleteRestricted = {
  /** Whether or not this thing can be deleted at the moment. */
  canBeDeleted: Scalars['Boolean'];
};

export type Document = Named & Node & Timestamped & {
  __typename?: 'Document';
  /** The MIME type of the content of this `Document`. */
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  modifiedAt: Scalars['DateTime'];
  /** The file name of this `Document`. */
  name: Scalars['String'];
  /** Size of this `Document` in bytes. */
  size: Scalars['Int'];
  /** The `DocumentTag`s that have been assigned to this `Document` */
  tags: Array<DocumentTag>;
};

export type DocumentConnection = Connection & {
  __typename?: 'DocumentConnection';
  edges: Array<DocumentEdge>;
  pageInfo: PageInfo;
};

export type DocumentEdge = Edge & {
  __typename?: 'DocumentEdge';
  cursor: Scalars['String'];
  node: Document;
};

/** The properties by which `Document`s can be ordered. */
export enum DocumentOrderBy {
  CreatedAt = 'CreatedAt',
  Name = 'Name'
}

/** A tag that is used to organize `Document`s. */
export type DocumentTag = Named & Node & Timestamped & {
  __typename?: 'DocumentTag';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  modifiedAt: Scalars['DateTime'];
  /** The name of this `DocumentTag`. */
  name: Scalars['String'];
};

/** An edge contains a `Node` inside a `Connection`. */
export type Edge = {
  /** The curser of `node` in the `Connection`. */
  cursor: Scalars['String'];
  /** This edges `Node`. */
  node: Node;
};

export type Expense = Node & {
  __typename?: 'Expense';
  balance: Money;
  descriptor: ExpenseDescriptor;
  discount: Money;
  due: Money;
  hasReceipt: Scalars['Boolean'];
  hasReceiptContributions: Scalars['Boolean'];
  id: Scalars['ID'];
  nominal: Money;
  payed: Money;
  payments: Array<Payment>;
};

export type ExpenseDescriptor = Named & Node & {
  __typename?: 'ExpenseDescriptor';
  amount: Money;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ExpenseReceipt = Node & {
  __typename?: 'ExpenseReceipt';
  databaseId: Scalars['ID'];
  generatedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  note: Scalars['String'];
  rescinded: Scalars['Boolean'];
};

/** A `MemberPortalUser` allows a `Perons` to access the member portal. */
export type MemberPortalUser = Node & ProcessResource & Timestamped & {
  __typename?: 'MemberPortalUser';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The `CourseLevel`s for which this user has access to the course materials. */
  materialAccessCourseLevels: Array<CourseLevel>;
  modifiedAt: Scalars['DateTime'];
  /** The `Person` this `AdminAppUser` belongs to. */
  person: Person;
  process: DataProcessingProcess;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type MoneyInput = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates an `AdminAppUser`. */
  createAdminAppUser: AdminAppUser;
  createCourse: Course;
  createCourseAdminFile: CourseAdminFile;
  createCourseAdminFileExpenseReceipt: ExpenseReceipt;
  createCourseAdminGroup: CourseAdminGroup;
  createCourseEvent: CourseEvent;
  createCourseOptionBooking: CourseOptionBooking;
  createCourseOptionBookingPayment: Payment;
  createCourseOptionDescriptor: CourseOptionDescriptor;
  createCoursePart: CoursePart;
  createCourseParticipationCertificate: CourseParticipationCertificate;
  createDataProcessingAgreementChangeEvent?: Maybe<DataProcessingAgreementChangeEvent>;
  /**
   * Creates a `DocumentTag`.
   *
   * Requires the Admin role.
   */
  createDocumentTag: DocumentTag;
  /** Creates a `MemberPortalUser`. */
  createMemberPortalUser: MemberPortalUser;
  createOrganization: Organization;
  /** Creates a new membership and returns it. */
  createOrganizationMembership: OrganizationMembership;
  createPerson: Person;
  /** Deletes an `AdminAppUser`. */
  deleteAdminAppUser: Scalars['Boolean'];
  deleteCourse: Scalars['Boolean'];
  deleteCourseAdminFile: Scalars['Boolean'];
  deleteCourseAdminFileExpenseReceipt: Scalars['Boolean'];
  deleteCourseAdminGroup: Scalars['Boolean'];
  deleteCourseEvent: Scalars['Boolean'];
  deleteCourseOptionBooking: Scalars['Boolean'];
  deleteCourseOptionBookingPayment: Scalars['Boolean'];
  deleteCourseOptionDescriptor: Scalars['Boolean'];
  deleteCoursePart: Scalars['Boolean'];
  deleteCourseParticipationCertificate: Scalars['Boolean'];
  deleteDataProcessingAgreementChangeEvent?: Maybe<Scalars['Boolean']>;
  /**
   * Deletes a `Document`.
   *
   * Requires the Admin role.
   */
  deleteDocument: Scalars['Boolean'];
  /**
   * Deletes a `DocumentTag`.
   *
   * Requires the Admin role.
   */
  deleteDocumentTag: Scalars['Boolean'];
  /** Updates a `MemberPortalUser`. */
  deleteMemberPortalUser: Scalars['Boolean'];
  deleteOrganization: Scalars['Boolean'];
  /** Deletes an existing membership and returns true. */
  deleteOrganizationMembership: Scalars['Boolean'];
  deletePerson: Scalars['Boolean'];
  /** Signs up a `Person` for the newsletter. */
  signUpForNewsletter: Scalars['Boolean'];
  /** Signs up a new `MemberPortalUser`. */
  signUpMemberPortalUser: MemberPortalUser;
  /** Updates an `AdminAppUser`. */
  updateAdminAppUser: AdminAppUser;
  updateCourse: Course;
  updateCourseAdminFile: CourseAdminFile;
  updateCourseAdminFileExpenseReceipt: ExpenseReceipt;
  updateCourseAdminGroup: CourseAdminGroup;
  updateCourseEvent: CourseEvent;
  updateCourseOptionBooking: CourseOptionBooking;
  updateCourseOptionDescriptor: CourseOptionDescriptor;
  updateCoursePart: CoursePart;
  updateCourseParticipationCertificate: CourseParticipationCertificate;
  updateDataProcessingAgreementChangeEvent?: Maybe<DataProcessingAgreementChangeEvent>;
  /** Updates a `Document`. */
  updateDocument: Document;
  /**
   * Updates a `DocumentTag`.
   *
   * Requires the Admin role.
   */
  updateDocumentTag: DocumentTag;
  /** Updates a `MemberPortalUser`. */
  updateMemberPortalUser: MemberPortalUser;
  updateOrganization: Organization;
  /** Updates an existing membership and returns it. */
  updateOrganizationMembership: OrganizationMembership;
  updatePerson: Person;
  /** Updates an `UserAccount`. */
  updateUserAccount: UserAccount;
};


export type MutationCreateAdminAppUserArgs = {
  input: CreateAdminAppUserInput;
};


export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};


export type MutationCreateCourseAdminFileArgs = {
  input: CreateCourseAdminFileInput;
};


export type MutationCreateCourseAdminFileExpenseReceiptArgs = {
  input: CreateCourseAdminFileExpenseReceiptInput;
};


export type MutationCreateCourseAdminGroupArgs = {
  input: CreateCourseAdminGroupInput;
};


export type MutationCreateCourseEventArgs = {
  input: CreateCourseEventInput;
};


export type MutationCreateCourseOptionBookingArgs = {
  input: CreateCourseOptionBookingInput;
};


export type MutationCreateCourseOptionBookingPaymentArgs = {
  input: CreateCourseOptionBookingPaymentInput;
};


export type MutationCreateCourseOptionDescriptorArgs = {
  input: CreateCourseOptionDescriptorInput;
};


export type MutationCreateCoursePartArgs = {
  input: CreateCoursePartInput;
};


export type MutationCreateCourseParticipationCertificateArgs = {
  input?: InputMaybe<CreateCourseParticipationCertificateInput>;
};


export type MutationCreateDataProcessingAgreementChangeEventArgs = {
  input: CreateDataProcessingAgreementChangeEventInput;
};


export type MutationCreateDocumentTagArgs = {
  input: CreateDocumentTagInput;
};


export type MutationCreateMemberPortalUserArgs = {
  input: CreateMemberPortalUserInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrganizationMembershipArgs = {
  input: CreateOrganizationMembershipInput;
};


export type MutationCreatePersonArgs = {
  input?: InputMaybe<CreatePersonInput>;
};


export type MutationDeleteAdminAppUserArgs = {
  input: DeleteAdminAppUserInput;
};


export type MutationDeleteCourseArgs = {
  input: DeleteCourseInput;
};


export type MutationDeleteCourseAdminFileArgs = {
  input: DeleteCourseAdminFileInput;
};


export type MutationDeleteCourseAdminFileExpenseReceiptArgs = {
  input: DeleteCourseAdminFileExpenseReceiptInput;
};


export type MutationDeleteCourseAdminGroupArgs = {
  input: DeleteCourseAdminGroupInput;
};


export type MutationDeleteCourseEventArgs = {
  input: DeleteCourseEventInput;
};


export type MutationDeleteCourseOptionBookingArgs = {
  input: DeleteCourseOptionBookingInput;
};


export type MutationDeleteCourseOptionBookingPaymentArgs = {
  input: DeleteCourseOptionBookingPaymentInput;
};


export type MutationDeleteCourseOptionDescriptorArgs = {
  input: DeleteCourseOptionDescriptorInput;
};


export type MutationDeleteCoursePartArgs = {
  input: DeleteCoursePartInput;
};


export type MutationDeleteCourseParticipationCertificateArgs = {
  input?: InputMaybe<DeleteCourseParticipationCertificateInput>;
};


export type MutationDeleteDataProcessingAgreementChangeEventArgs = {
  input: DeleteDataProcessingAgreementChangeEventInput;
};


export type MutationDeleteDocumentArgs = {
  input: DeleteDocumentInput;
};


export type MutationDeleteDocumentTagArgs = {
  input: DeleteDocumentTagInput;
};


export type MutationDeleteMemberPortalUserArgs = {
  input: DeleteMemberPortalUserInput;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


export type MutationDeleteOrganizationMembershipArgs = {
  input: DeleteOrganizationMembershipInput;
};


export type MutationDeletePersonArgs = {
  input: DeletePersonInput;
};


export type MutationSignUpForNewsletterArgs = {
  input: SignUpForNewsletterInput;
};


export type MutationSignUpMemberPortalUserArgs = {
  input: SignUpMemberPortalUserInput;
};


export type MutationUpdateAdminAppUserArgs = {
  input: UpdateAdminAppUserInput;
};


export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput;
};


export type MutationUpdateCourseAdminFileArgs = {
  input: UpdateCourseAdminFileInput;
};


export type MutationUpdateCourseAdminFileExpenseReceiptArgs = {
  input: UpdateCourseAdminFileExpenseReceiptInput;
};


export type MutationUpdateCourseAdminGroupArgs = {
  input: UpdateCourseAdminGroupInput;
};


export type MutationUpdateCourseEventArgs = {
  input: UpdateCourseEventInput;
};


export type MutationUpdateCourseOptionBookingArgs = {
  input: UpdateCourseOptionBookingInput;
};


export type MutationUpdateCourseOptionDescriptorArgs = {
  input: UpdateCourseOptionDescriptorInput;
};


export type MutationUpdateCoursePartArgs = {
  input: UpdateCoursePartInput;
};


export type MutationUpdateCourseParticipationCertificateArgs = {
  input?: InputMaybe<UpdateCourseParticipationCertificateInput>;
};


export type MutationUpdateDataProcessingAgreementChangeEventArgs = {
  input: UpdateDataProcessingAgreementChangeEventInput;
};


export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


export type MutationUpdateDocumentTagArgs = {
  input: UpdateDocumentTagInput;
};


export type MutationUpdateMemberPortalUserArgs = {
  input: UpdateMemberPortalUserInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationMembershipArgs = {
  input: UpdateOrganizationMembershipInput;
};


export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput;
};


export type MutationUpdateUserAccountArgs = {
  input: UpdateUserAccountInput;
};

/** A thing which has a name. */
export type Named = {
  /** The name of this thing. */
  name: Scalars['String'];
};

/**
 * A thing which can be fetched from the `Query.node` field.
 *
 * This has the advantage that, all things which implement this interface can be fetched through a single field, instead
 * of requiring their own individual field in `Query`.
 */
export type Node = {
  /** The id of this `Node`. */
  id: Scalars['ID'];
};

/** The direction of an ordered list. */
export enum OrderDirection {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export type Organization = Named & Node & Timestamped & {
  __typename?: 'Organization';
  address: PostalAddress;
  courses: CourseConnection;
  createdAt: Scalars['DateTime'];
  /** All the `DocumentTag`s that belong to this `Organization`. */
  documentTags: Array<DocumentTag>;
  /**
   * All the `Document`s that belong to this `Organization`.
   *
   * If `tags` is specified, only `Document`s with all the specified `DocumentTag`s are included.
   *
   * The order of the returned connection can be controlled through `orderBy` and `orderDirection`.
   */
  documents: DocumentConnection;
  id: Scalars['ID'];
  /** Whether members in this organization are required to make reoccurring contributions. */
  membershipRequiresContribution: Scalars['Boolean'];
  memberships: OrganizationMembershipConnection;
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  officialName: Scalars['String'];
  receiptsFooter?: Maybe<Scalars['String']>;
};


export type OrganizationCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type OrganizationDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: DocumentOrderBy;
  orderDirection?: OrderDirection;
  tags?: InputMaybe<Array<Scalars['ID']>>;
};


export type OrganizationMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The membership of a `Person` in an `Organization`. */
export type OrganizationMembership = Node & ProcessResource & Timestamped & {
  __typename?: 'OrganizationMembership';
  /** The date of the creation of this membership. */
  createdAt: Scalars['DateTime'];
  /** The end date of this membership. */
  endDate?: Maybe<Scalars['Date']>;
  /** Whether or not this membership has an attached file. */
  hasAttachment: Scalars['Boolean'];
  /** The id of this membership. */
  id: Scalars['ID'];
  /** Whether or not this membership is active. */
  isActive: Scalars['Boolean'];
  /** The date of the last modification of this membership. */
  modifiedAt: Scalars['DateTime'];
  /** The organization to which this membership belongs. */
  organization: Organization;
  /** The person which is the member of the organization. */
  person: Person;
  process: DataProcessingProcess;
  /** The start date of this membership. */
  startDate: Scalars['Date'];
};

export type OrganizationMembershipConnection = Connection & {
  __typename?: 'OrganizationMembershipConnection';
  edges: Array<OrganizationMembershipEdge>;
  pageInfo: PageInfo;
};

export type OrganizationMembershipEdge = Edge & {
  __typename?: 'OrganizationMembershipEdge';
  cursor: Scalars['String'];
  node: OrganizationMembership;
};

/** Information about a page, which is part of a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor to the last `Node` in the connection. */
  endCursor: Scalars['String'];
  /** Whether there is a next page in the `Connection`. */
  hasNextPage: Scalars['Boolean'];
  /** Whether there is a previous page in the `Connection`. */
  hasPreviousPage: Scalars['Boolean'];
  /** The number of the current page. */
  page?: Maybe<Scalars['Int']>;
  /** The size of the current page. */
  size: Scalars['Int'];
  /** The cursor to the first `Node` in the connection. */
  startCursor: Scalars['String'];
  /** The total number of edges in the connection. */
  totalEdges?: Maybe<Scalars['Int']>;
  /** The total number of pages in the connection. */
  totalPages?: Maybe<Scalars['Int']>;
};

export type Payment = Node & {
  __typename?: 'Payment';
  amount: Money;
  id: Scalars['ID'];
  method: PaymentMethod;
  receivedAt: Scalars['DateTime'];
};

export type PaymentInput = {
  amount?: InputMaybe<MoneyInput>;
  id?: InputMaybe<Scalars['ID']>;
  method?: InputMaybe<PaymentMethod>;
  receivedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum PaymentMethod {
  BankTransfer = 'BankTransfer',
  Cash = 'Cash'
}

export type Person = DeleteRestricted & Node & Timestamped & {
  __typename?: 'Person';
  /** The `AdminAppUser` of this `Person`, if it exists. */
  adminAppUser?: Maybe<AdminAppUser>;
  canBeDeleted: Scalars['Boolean'];
  courseParticipationCertificates: Array<CourseParticipationCertificate>;
  createdAt: Scalars['DateTime'];
  dataProcessingAgreementChangeEvents: Array<DataProcessingAgreementChangeEvent>;
  dataProcessingProcesses: DataProcessingProcessConnection;
  databaseId: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<PersonEmail>;
  homeAddress?: Maybe<PersonAddress>;
  id: Scalars['ID'];
  /** The `MemberPortalUser` of this `Person`, if it exists. */
  memberPortalUser?: Maybe<MemberPortalUser>;
  modifiedAt: Scalars['DateTime'];
  name?: Maybe<PersonName>;
  organizationMemberships: Array<OrganizationMembership>;
  phone?: Maybe<PersonPhone>;
  /** The `UserAccount` of this `Person`, if it exists. */
  userAccount?: Maybe<UserAccount>;
};


export type PersonDataProcessingProcessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PersonAddress = DeleteRestricted & PersonScopeData & {
  __typename?: 'PersonAddress';
  address: PostalAddress;
  canBeDeleted: Scalars['Boolean'];
};

export type PersonConnection = Connection & {
  __typename?: 'PersonConnection';
  edges: Array<PersonEdge>;
  pageInfo: PageInfo;
};

export type PersonEdge = Edge & {
  __typename?: 'PersonEdge';
  cursor: Scalars['String'];
  node: Person;
};

export type PersonEmail = DeleteRestricted & PersonScopeData & {
  __typename?: 'PersonEmail';
  canBeDeleted: Scalars['Boolean'];
  emailAddress: Scalars['String'];
};

export type PersonEmailInput = {
  emailAddress: Scalars['String'];
};

/** Filters to limit which `Person`s are returned. */
export type PersonFilters = {
  /** `Person` has to have a set of `UserRole`s. */
  hasAdminAppRole?: InputMaybe<Array<UserRole>>;
  /** `Person` has to have currently agreed to all the given `DataProcessingActivity`. */
  hasAgreedToDataProcessingActivities?: InputMaybe<Array<Scalars['ID']>>;
  /** `Person` has to have at least one `CourseParticipationCertificate` for each of the given levels. */
  hasCourseParticipationCertificatesWithLevels?: InputMaybe<Array<CourseLevel>>;
  /** `Person` has one or more `DataProcessingProcess`es where `permissionExists` is `false`. */
  hasDataProcessingProcessWithoutPermission?: InputMaybe<Scalars['Boolean']>;
  /** `Person` has at least some data which can be deleted. */
  hasDeletableData?: InputMaybe<Scalars['Boolean']>;
  /** `Person` has to have access to the materials of a set of `CourseLevel`s. */
  hasMaterialAccessCourseLevels?: InputMaybe<Array<CourseLevel>>;
  /** `Person` has to have at least one `CourseAdminFile` for which no `CourseParticipationCertificate` has been saved. */
  hasUnsavedCourseParticipationCertificate?: InputMaybe<Scalars['Boolean']>;
  /** `Person` has to be an `AdminAppUser`. */
  isAdminAppUser?: InputMaybe<Scalars['Boolean']>;
  /** `Person` can be deleted. */
  isDeletable?: InputMaybe<Scalars['Boolean']>;
  /** `Person` is a member of one or all of the given Organizations. */
  isMemberOfOrganizations?: InputMaybe<Array<Scalars['ID']>>;
  /** `Person` has to be a `MemberPortalUser`. */
  isMemberPortalUser?: InputMaybe<Scalars['Boolean']>;
  /** How to combine facets when more than one is given. Defaults to `And`. */
  operator?: InputMaybe<QueryOperator>;
};

export type PersonName = DeleteRestricted & PersonScopeData & {
  __typename?: 'PersonName';
  canBeDeleted: Scalars['Boolean'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
};

export type PersonNameInput = {
  familyName: Scalars['String'];
  givenName: Scalars['String'];
};

export type PersonPhone = DeleteRestricted & PersonScopeData & {
  __typename?: 'PersonPhone';
  canBeDeleted: Scalars['Boolean'];
  primaryNumber: Scalars['String'];
  secondaryNumber?: Maybe<Scalars['String']>;
};

export type PersonPhoneInput = {
  primaryNumber: Scalars['String'];
  secondaryNumber?: InputMaybe<Scalars['String']>;
};

export enum PersonScope {
  Email = 'Email',
  HomeAddress = 'HomeAddress',
  Name = 'Name',
  Phone = 'Phone'
}

export type PersonScopeData = {
  canBeDeleted: Scalars['Boolean'];
};

/** A postal address. */
export type PostalAddress = {
  __typename?: 'PostalAddress';
  /** The first line of this address. */
  addressLine1: Scalars['String'];
  /** The second line of this address. */
  addressLine2?: Maybe<Scalars['String']>;
  /** The area of this address. */
  area?: Maybe<Scalars['String']>;
  /** The city of this address. */
  city: Scalars['String'];
  /** The country of this address. */
  country: Scalars['String'];
  /** The postal code of this address. */
  postalCode: Scalars['String'];
};

/** Input for `PostalAddress`. */
export type PostalAddressInput = {
  /** The first line of this address. */
  addressLine1: Scalars['String'];
  /** The second line of this address. */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** The area of this address. */
  area?: InputMaybe<Scalars['String']>;
  /** The city of this address. */
  city: Scalars['String'];
  /** The country of this address. */
  country: Scalars['String'];
  /** The postal code of this address. */
  postalCode: Scalars['String'];
};

/** A resource which represents a `DataProcessingProcess`. */
export type ProcessResource = {
  /** The id of this resource. */
  id: Scalars['ID'];
  /** The `DataProcessingProcess` this resource is representing. */
  process: DataProcessingProcess;
};

export type ProcessRetentionConfig = {
  __typename?: 'ProcessRetentionConfig';
  duration: Scalars['Int'];
  startAdjuster: RetentionStartAdjuster;
  unit: TimeUnit;
};

export enum ProcessState {
  Open = 'Open',
  Passive = 'Passive',
  Retention = 'Retention',
  Terminated = 'Terminated'
}

export type Query = {
  __typename?: 'Query';
  /**
   * Returns a connection of `AuditEvent`s, supporting filtering by `time`, `actorId` and
   * `resourceId` of the event.
   */
  auditEvents: AuditEventConnection;
  dataProcessingActivities: Array<DataProcessingActivity>;
  /** Returns the `Node` identified by `id`. */
  node: Node;
  organizations: Array<Organization>;
  personSearch: Array<Person>;
  persons: PersonConnection;
  /** The `UserAccount` of the currently authenticated user, if it exists. */
  viewerUserAccount?: Maybe<UserAccount>;
};


export type QueryAuditEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  afterTime?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['String']>;
  beforeTime?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  resources?: InputMaybe<AuditResourceMatchers>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryPersonSearchArgs = {
  query: Scalars['String'];
};


export type QueryPersonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PersonFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The operation to use when a query has multiple facets. */
export enum QueryOperator {
  /** An item must match every facet. */
  And = 'And',
  /** An item must match at least one facet. */
  Or = 'Or'
}

export enum RetentionStartAdjuster {
  FirstDayOfNextYear = 'FirstDayOfNextYear',
  Immediately = 'Immediately'
}

/** Input for `signUpForNewsletter`. */
export type SignUpForNewsletterInput = {
  /** The email address of the `Person` to sign up. */
  email: PersonEmailInput;
  /** The name of the person to sign up. */
  name: PersonNameInput;
};

export type SignUpMemberPortalUserInput = {
  name: PersonNameInput;
};

/** A date period unit. */
export enum TimeUnit {
  /** Unit that represents the concept of a year. */
  Years = 'Years'
}

/** A thing which includes timestamps of creation and modification. */
export type Timestamped = {
  /** The date of the creation of this thing. */
  createdAt: Scalars['DateTime'];
  /** The date of the last modification of this thing. */
  modifiedAt: Scalars['DateTime'];
};

export type UpdateAdminAppUserInput = {
  id: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  roles?: InputMaybe<Array<UserRole>>;
};

export type UpdateCourseAdminFileExpenseReceiptInput = {
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  rescinded?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCourseAdminFileInput = {
  eventAttendances?: InputMaybe<Array<CourseEventAttendanceInput>>;
  id: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isRescinded?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCourseAdminGroupInput = {
  id: Scalars['ID'];
  /**
   * Whether this group is archived.
   *
   * See:
   *
   * - `CourseAdminGroup.isArchived`
   */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  partIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateCourseEventInput = {
  coursePartId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateCourseInput = {
  id: Scalars['ID'];
  level?: InputMaybe<CourseLevel>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCourseOptionBookingInput = {
  discount?: InputMaybe<MoneyInput>;
  id: Scalars['ID'];
};

export type UpdateCourseOptionDescriptorInput = {
  description?: InputMaybe<Scalars['String']>;
  expenseAmount?: InputMaybe<MoneyInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCoursePartInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateCourseParticipationCertificateInput = {
  completion?: InputMaybe<Scalars['Float']>;
  courseSummary: CourseParticipationCourseSummaryInput;
  id: Scalars['ID'];
};

export type UpdateDataProcessingAgreementChangeEventInput = {
  changes?: InputMaybe<Array<DataProcessingAgreementChangeInput>>;
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type UpdateDocumentInput = {
  id: Scalars['ID'];
  tags?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateDocumentTagInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateMemberPortalUserInput = {
  id: Scalars['ID'];
  materialAccessCourseLevels?: InputMaybe<Array<CourseLevel>>;
};

export type UpdateOrganizationInput = {
  address?: InputMaybe<PostalAddressInput>;
  id: Scalars['ID'];
  membershipRequiresContribution?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  officialName?: InputMaybe<Scalars['String']>;
  receiptsFooter?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationMembershipInput = {
  /** The new start date of the membership. Setting this date ends the membership. */
  endDate?: InputMaybe<Scalars['Date']>;
  /** The id of the membership to update. */
  id: Scalars['ID'];
  /** The new start date of the membership. */
  startDate?: InputMaybe<Scalars['Date']>;
};

export type UpdatePersonInput = {
  email?: InputMaybe<PersonEmailInput>;
  homeAddress?: InputMaybe<PostalAddressInput>;
  id: Scalars['ID'];
  name?: InputMaybe<PersonNameInput>;
  phone?: InputMaybe<PersonPhoneInput>;
};

/** A thing which has restrictions on when it can be update. */
export type UpdateRestricted = {
  /** Whether or not this thing can be updated at the moment. */
  canBeUpdated: Scalars['Boolean'];
};

export type UpdateUserAccountInput = {
  id: Scalars['ID'];
  mfa?: InputMaybe<Scalars['Boolean']>;
};

export type UserAccount = Node & {
  __typename?: 'UserAccount';
  id: Scalars['ID'];
  /** Whether multi factor authentication is enabled and required for the user signs in. */
  mfa: Scalars['Boolean'];
  /** The `Person` this `UserAccount` belongs to. */
  person: Person;
};

/**
 * A role, which can be assigned to an `AdminAppUser`. Roles govern the access rights of a
 * `AdminAppUser`.
 */
export enum UserRole {
  /** Grants an `AdminAppUser` access to the complete system. */
  Admin = 'Admin',
  /** Grants an `AdminAppUser` access to most of the system. */
  Staff = 'Staff'
}

export type CurrentViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentViewerQuery = { __typename?: 'Query', viewerUserAccount?: { __typename?: 'UserAccount', id: string } | null };

export type UserAccountFragment = { __typename?: 'UserAccount', id: string, person: { __typename?: 'Person', id: string, displayName?: string | null, memberPortalUser?: { __typename?: 'MemberPortalUser', id: string, materialAccessCourseLevels: Array<CourseLevel> } | null, adminAppUser?: { __typename?: 'AdminAppUser', id: string } | null } };

export type GetUserAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAccountQuery = { __typename?: 'Query', viewerUserAccount?: { __typename?: 'UserAccount', id: string, person: { __typename?: 'Person', id: string, displayName?: string | null, memberPortalUser?: { __typename?: 'MemberPortalUser', id: string, materialAccessCourseLevels: Array<CourseLevel> } | null, adminAppUser?: { __typename?: 'AdminAppUser', id: string } | null } } | null };

export type SignUpUserMutationVariables = Exact<{
  input: SignUpMemberPortalUserInput;
}>;


export type SignUpUserMutation = { __typename?: 'Mutation', signUpMemberPortalUser: { __typename?: 'MemberPortalUser', id: string } };

export const UserAccountFragmentDoc = gql`
    fragment UserAccount on UserAccount {
  id
  person {
    id
    displayName
    memberPortalUser {
      id
      materialAccessCourseLevels
    }
    adminAppUser {
      id
    }
  }
}
    `;
export const CurrentViewerDocument = gql`
    query CurrentViewer {
  viewerUserAccount {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentViewerGQL extends Apollo.Query<CurrentViewerQuery, CurrentViewerQueryVariables> {
    document = CurrentViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserAccountDocument = gql`
    query GetUserAccount {
  viewerUserAccount {
    ...UserAccount
  }
}
    ${UserAccountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserAccountGQL extends Apollo.Query<GetUserAccountQuery, GetUserAccountQueryVariables> {
    document = GetUserAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignUpUserDocument = gql`
    mutation SignUpUser($input: SignUpMemberPortalUserInput!) {
  signUpMemberPortalUser(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignUpUserGQL extends Apollo.Mutation<SignUpUserMutation, SignUpUserMutationVariables> {
    document = SignUpUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }