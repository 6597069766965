import { LayoutModule } from '@angular/cdk/layout'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatSidenavModule } from '@angular/material/sidenav'
import { SidenavContainerComponent } from './sidenav-container/sidenav-container.component'
import { SidenavContentComponent } from './sidenav-content/sidenav-content.component'
import { SidenavComponent } from './sidenav/sidenav.component'

@NgModule({
  imports: [CommonModule, MatSidenavModule, LayoutModule],
  declarations: [
    SidenavContainerComponent,
    SidenavContentComponent,
    SidenavComponent,
  ],
  exports: [
    SidenavContainerComponent,
    SidenavContentComponent,
    SidenavComponent,
  ],
})
export class SidenavLayoutModule {}
